import { useContext } from 'react'
import { DeviceCarouselSlideContext } from '../DeviceCarousel'

export const useDeviceCarouselSlide = () => {
  const slideData = useContext(DeviceCarouselSlideContext)

  if (slideData === undefined) {
    throw new Error(
      '`useDeviceCarouselSlide` hook must be used within a `DeviceCarousel` component',
    )
  }

  return slideData
}
