import { OrderStatus } from 'modules/MobilecloudAPI/types'

export const orderStatusText = {
  [OrderStatus.AwaitingPayment]: 'Your payment is being verified.',
  [OrderStatus.Canceled]: 'Canceled',
  [OrderStatus.Delivered]: 'Delivered',
  [OrderStatus.DeliveryException]: 'Shipment Exception',
  [OrderStatus.LabelCreated]: 'Shipping label generated. Awaiting pickup.',
  [OrderStatus.NoShipment]: 'No Shipment',
  [OrderStatus.Processed]: 'Processed',
  [OrderStatus.Processing]: 'Processing',
  [OrderStatus.Shipped]: 'On its way...',
}
