import React, { FC, ComponentPropsWithoutRef } from 'react'
import isNumber from 'lodash/fp/isNumber'
import styles from './BatteryGauge.module.scss'
import { LoadingPlaceholder } from 'components/LoadingPlaceholder'
import { classNames } from 'utils/css'
import { Icon } from '@juullabs/react-components'

export interface BatteryGaugeProps extends ComponentPropsWithoutRef<'div'> {
  /** the amount of battery remaining 0-100 */
  batteryLevel?: number
  /** whether the batteries are charging */
  charging?: boolean
  /** loading state */
  loading?: boolean
  /** total length of the battery including the end */
  width?: number
}

// Battery Gauge Config
const BASE_WIDTH = 58
const MAX_BORDER_RADIUS = 4.5
const MIN_BORDER_RADIUS = 1
const MAX_BATTERY_LEVEL = 100
const MIN_BATTERY_LEVEL = 10
const INNER_OFFSET_WIDTH = 8
const OUTER_OFFSET_WIDTH = 3
const CHARGING_ICON = 'icon/24x24/charging_filled'

export const BatteryGauge: FC<BatteryGaugeProps> = ({
  batteryLevel,
  charging = false,
  loading = false,
  width = BASE_WIDTH,
  ...rest
}) => {
  const hasData = isNumber(batteryLevel)
  const lowBattery = batteryLevel <= MIN_BATTERY_LEVEL
  const level = lowBattery ? MIN_BATTERY_LEVEL : batteryLevel

  // variables for right radius calculation
  const innerWidth = width - INNER_OFFSET_WIDTH
  const radiusDifference = MAX_BORDER_RADIUS - MIN_BORDER_RADIUS
  const cutoffWidth = MAX_BATTERY_LEVEL - (
    (radiusDifference * MAX_BATTERY_LEVEL) / innerWidth
  )

  const getRightRadius = (level: number) => {
    if (level >= MAX_BATTERY_LEVEL) {
      return MAX_BORDER_RADIUS
    } else if (level <= cutoffWidth) {
      return MIN_BORDER_RADIUS
    } else {
      return ((
        (level - cutoffWidth) / (MAX_BATTERY_LEVEL - cutoffWidth)
      ) * radiusDifference) + MIN_BORDER_RADIUS
    }
  }

  const rightBorderRadius = getRightRadius(level)

  return (
    <div className={styles.BatteryGauge} role='presentation' {...rest}>
      <div
        className={classNames(
          styles.batteryMain,
          loading && styles.loading,
        )}
        style={{ width: `${width - OUTER_OFFSET_WIDTH}px` }}
      >
        {loading ? (
          <LoadingPlaceholder className={styles.loader} />
        ) : hasData && (
          <>
            <div className={styles.batteryLevelWrap}>
              <div
                className={classNames(
                  styles.batteryLevel,
                  charging && !lowBattery && styles.charging,
                  lowBattery && styles.lowBattery,
                )}
                style={{
                  borderBottomRightRadius: `${rightBorderRadius}px`,
                  borderTopRightRadius: `${rightBorderRadius}px`,
                  width: `${level}%`,
                }}
              />
            </div>
            {charging && (
              <Icon
                name={CHARGING_ICON}
                className={styles.chargingIcon}
              />
            )}
          </>
        )}
      </div>
      <div className={classNames(
        styles.batteryEnd,
        loading && styles.loading,
      )}/>
    </div>
  )
}
