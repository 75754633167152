import { FetchStatus, MostRecentOrderState, SubscriptionState } from './types'
import { getMostRecentOrder, getSubscription } from 'modules/MobilecloudAPI'
import { bugsnagClient } from 'utils/bugsnag'
import { Order, Subscription } from 'modules/MobilecloudAPI'

const NOT_FOUND_REG_EXP = new RegExp(/(404)/, 'g')

export const fetchSubscriptionData = async(
  set: (state: SubscriptionState) => void,
) => {
  let status: FetchStatus = FetchStatus.ERROR
  let payload: Subscription

  try {
    payload = await getSubscription()
    if (payload?.status === 'active') {
      status = FetchStatus.SUCCESS
    } else {
      status = FetchStatus.NOT_FOUND
    }
  } catch (e) {
    if (e.response?.status === 404
      || e.message?.match(NOT_FOUND_REG_EXP)?.length > 0) {
      status = FetchStatus.NOT_FOUND
    } else {
      bugsnagClient?.notify?.({
        message: `${e}`,
        name: 'subscription_data_failure',
      })
    }
  }

  set({ payload, status })
}

export const fetchMostRecentOrderData = async(
  set: (state: MostRecentOrderState) => void,
) => {
  let status: FetchStatus = FetchStatus.ERROR
  let payload: Order

  try {
    payload = await getMostRecentOrder()
    if (payload) {
      status = FetchStatus.SUCCESS
    } else {
      status = FetchStatus.NOT_FOUND
    }
  } catch (e) {
    bugsnagClient?.notify?.({
      message: `${e}`,
      name: 'most-recent-order_data_failure',
    })
  }

  set({ payload, status })
}
