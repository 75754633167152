import React, { FC } from 'react'
import { Text } from 'components/Text'
import { BackgroundTile, BackgroundTileProps } from '../BackgroundTile'
import { DeviceButton } from '../DeviceButton'
import { newDeviceCardData } from './localization'
import { useDeviceCarouselSlide } from '../DeviceCarousel'

export interface NewDeviceCardProps extends Omit<BackgroundTileProps, 'colors'> {
  connectNewDevice(): void
}

export const NewDeviceCard: FC<NewDeviceCardProps> = ({
  connectNewDevice,
  ...rest
}) => {
  const { isInactive } = useDeviceCarouselSlide()
  const { heading, instruction, button } = newDeviceCardData

  return (
    <BackgroundTile tagName='article' {...rest}>
      <Text
        color='titanium|base|100'
        message={heading}
        tagName='h3'
        typography='h3'
      />
      <Text
        color='titanium|base|50'
        message={instruction}
        tagName='p'
        typography='bodyBaseBold'
      />
      <DeviceButton
        {...button}
        disabled={isInactive}
        onClick={connectNewDevice}
      />
    </BackgroundTile>
  )
}
