import React, { FC, useState, useMemo } from 'react'
import { isEmpty } from 'lodash'
import { Section } from '../components/Section'
import { FeedbackForm, StarRatingForm } from './components/FeedbackForm'
import { useLocalStorage } from 'hooks'
import { getAppSemverVersion } from 'utils/app'
import { useAnalytics } from 'modules/AnalyticsProvider'
import { WebAppFeedback } from 'analytics-events'

export const FEEDBACK_HISTORY_KEY = 'feedback-history'
export const feedbackSectionHeading = 'Give Feedback'

export const Feedback: FC = () => {
  // context
  const { reportEvent } = useAnalytics()

  // state
  const [isSubmitted, setIsSubmitted] = useState(false)

  // local storage
  const [feedbackHistory, setFeedbackHistory] = useLocalStorage(FEEDBACK_HISTORY_KEY, {})

  // variables
  const currentAppVersion = getAppSemverVersion()
  const isFirstFeedback = isEmpty(feedbackHistory)
  const hasSubmittedCurrentFeedback = Boolean(feedbackHistory[currentAppVersion])

  // memoized values (to avoid re-rendering)
  const promptType = useMemo<WebAppFeedback.FeedbackPrompt>(() => (
    isFirstFeedback
      ? WebAppFeedback.FeedbackPrompt.Default
      : WebAppFeedback.FeedbackPrompt.CurrentVersion
  ), [])

  // function to run when the feedback form is submitted
  const handleFeedbackSubmit = (values: StarRatingForm) => {
    setIsSubmitted(true)
    setFeedbackHistory({ [currentAppVersion]: true, ...feedbackHistory })
    reportEvent(WebAppFeedback.StarRatingEvent({
      feedbackPrompt: promptType,
      ...values,
    }))
  }

  return (hasSubmittedCurrentFeedback && !isSubmitted) ? null : (
    <Section heading={{ content: feedbackSectionHeading }}>
      <FeedbackForm
        isSubmitted={isSubmitted}
        onSubmitFeedback={handleFeedbackSubmit}
        promptType={promptType}
      />
    </Section>
  )
}
