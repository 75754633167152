import React, { FC } from 'react'
import styles from './AutoShip.module.scss'
import { Text } from 'components/Text'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Icon } from '@juullabs/react-components'
import { LinkButton } from 'components/Button'
import { autoShipData, AutoShipStatus } from './localization'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'

export interface AutoShipProps extends TileProps {
  errorOnClick?: () => void
  locale: string
  nextOrderDate: string
  status: AutoShipStatus
  total: string
}

export const AutoShip: FC<AutoShipProps> = ({
  errorOnClick,
  locale,
  nextOrderDate,
  status,
  total,
  ...rest
}) => {
  const { detailTitle, error, heading, statusText } = autoShipData

  return (
    <Tile className={styles.AutoShip} {...rest}>
      <header className={styles.header}>
        <Text
          color='jade|base|100'
          message={{ content: heading[locale] || heading.default }}
          tagName='h3'
          typography='h4'
        />
        <Icon
          className={styles.icon}
          name='icon/48x48/subscription'
          color='jade|base|100'
        />
      </header>
      <div className={styles.details}>
        <Tile.Info
          content={statusText[status]}
          contentStyle={status === AutoShipStatus.Error && {
            color: 'red|base|100',
            typography: 'bodyBaseBold',
          }}
          title={detailTitle.status}
        />
        {status === AutoShipStatus.Active && (
          <>
            <Tile.Info
              content={nextOrderDate}
              title={detailTitle.date}
            />
            <Tile.Info
              className={styles.secondRow}
              content={total}
              title={detailTitle.total}
            />
          </>
        )}
      </div>
      {status === AutoShipStatus.Error && (
        <>
          <Text
            className={styles.errorMessage}
            message={{ content: error.message }}
            padding={{ bottom: 'baseLoose', top: 'base' }}
            tagName='p'
            typography='bodyBase'
          />
          <LinkButton
            className={styles.linkButton}
            content={error.linkText}
            href={MobilecloudLink.AccountMembership}
            primary='red'
            onClick={errorOnClick}
            rel='noreferrer'
            size='small'
            target='_blank'
          />
        </>
      )}
    </Tile>
  )
}
