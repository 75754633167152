import Locales from 'types/locales'

export enum AutoShipStatus {
  Active = 'active',
  Error = 'error',
}

const heading = {
  [Locales.EN_GB]: 'Subscription',
  default: 'Auto‑Ship',
}

const detailTitle = {
  date: 'Next Order Date',
  status: 'Status',
  total: 'Total',
}

const error = {
  linkText: 'Learn more',
  message: 'There is an issue with your subscription.',
}

const statusText = {
  [AutoShipStatus.Active]: 'Active',
  [AutoShipStatus.Error]: 'Error',
}

export const autoShipData = {
  detailTitle,
  error,
  heading,
  statusText,
}
