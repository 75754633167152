import React, { FC, useState } from 'react'
import isNumber from 'lodash/fp/isNumber'
import styles from './Connected.module.scss'
import { DeviceButton } from '../DeviceButton'
import { InfoBox } from '../InfoBox'
import { PuffGauge } from '../PuffGauge'
import { BatteryGauge } from '../BatteryGauge'
import { classNames, variationSize } from 'utils/css'
import { connectedData, DeviceButtonIcons } from './localization'
import Chai from 'types/chai'
import { DeviceCardSizes, DeviceCardChild } from '../../types'
import { ComplexMessage, TextFormats } from 'components/Text'
import { EstimateStatus } from 'modules/UsageInsights'

export interface ConnectedProps extends DeviceCardChild {
  /** the amount of battery remaining 0-100 */
  batteryLevel?: number
  /** whether the batteries are charging */
  charging?: boolean
  /** an array of the one or two colors that define the pod flavor */
  colors?: string[]
  /** if the pod is connected or disconnected */
  hasPod?: boolean
   /** the amount of liquid remaining 0-100 */
  liquidLevel?: number
  /** is content loading */
  loading?: boolean
  /** callback to lock users device */
  lockDevice?(): void
  /** is the device locked */
  locked?: boolean
  /** JUUL branded flavor name */
  podFlavor?: string
  /** pod statuses */
  podStatus?: Chai.PodStatus
  /** pod strength usually in 'mg/mL' or '%' depending on local and product */
  podStrength?: string
  /** estimate of the puffs remaining on a users battery */
  puffsOnCharge?: Chai.UsageEstimate
  /** estimate of the puffs remaining in a users pod */
  puffsOnPod?: Chai.UsageEstimate
  /** callback to ring the users device */
  ringDevice(): void
  /** delineates the size and version of the DeviceCard */
  size: DeviceCardSizes
  /** callback to unlock users device */
  unlockDevice?(): void
}

const UNDEFINED_PUFF_GAUGE_VALUE = '-- mg/mL' // todo localize unit of measure
const UNDEFINED_BATTERY_GAUGE_VALUE = '--'
const POST_RING_DELAY_MS = 800 // time before user can ring the device again

export const Connected: FC<ConnectedProps> = ({
  batteryLevel,
  charging = false,
  colors,
  hasPod = false,
  inactive,
  liquidLevel,
  loading = false,
  lockDevice,
  locked = false,
  podFlavor,
  podStatus,
  podStrength,
  puffsOnCharge,
  puffsOnPod,
  ringDevice,
  size,
  unlockDevice,
}) => {
  const [isRingLoading, setIsRingLoading] = useState<boolean>(false)
  const sizeStyle = size && styles[variationSize('size', size)]
  const buttonSize = size === 5 ? 'base' : 'small'
  const {
    batteryBox,
    lockButton,
    podBox,
    ringButton,
    unlockButton,
  } = connectedData

  const hasPodConnected = hasPod && podStatus?.isPodPresent
  const hasAuthenticPod = hasPodConnected && podStatus?.isAuthentic
  const hasPodError = hasPodConnected && !podStatus?.isAuthentic
  const hasFlavorHue = hasAuthenticPod && !locked
  const buttonColor = !locked && !loading && hasPod && colors?.[1]
  const lockButtonContent = locked ? unlockButton : lockButton

  const getPodBoxHeading = ():TextFormats => {
    let heading

    if (!hasPodConnected) heading = podBox.heading.withoutPod
    else if (hasPodError) heading = podBox.heading.podError
    else if (!podFlavor) heading = podBox.heading.flavorUnavailable

    return { content: heading || podFlavor }
  }

  const getPodBoxDescription = ():ComplexMessage => {
    let description

    if (hasAuthenticPod) {
      if (liquidLevel <= 10) {
        description = { message: podBox.description.lowGauge }
      } else if (puffsOnPod?.status !== EstimateStatus.Calculated) {
        description = { message: podBox.description.withoutEstimate }
      } else {
        description = {
          message: podBox.description.withEstimate,
          values: { estimatedPuffsOnPod: puffsOnPod.estimate },
        }
      }
    }

    return description
  }

  const handleRingDevice = async() => {
    setIsRingLoading(true)

    await ringDevice()

    setTimeout(() => {
      setIsRingLoading(false)
    }, POST_RING_DELAY_MS)
  }

  return (
    <div className={classNames(styles.Connected, sizeStyle)}>
      <div className={styles.buttonGroup}>
        <DeviceButton
          accessibilityLabel={lockButtonContent}
          className={locked && styles.unlockButton}
          content={lockButtonContent}
          color={buttonColor}
          disabled={inactive}
          icon={locked ? DeviceButtonIcons.Unlock : DeviceButtonIcons.Lock}
          loading={loading}
          onClick={locked ? unlockDevice : lockDevice}
          size={buttonSize}
        />
        <DeviceButton
          accessibilityLabel={ringButton}
          content={ringButton}
          color={buttonColor}
          disabled={inactive}
          icon={DeviceButtonIcons.Ring}
          loading={loading || isRingLoading}
          onClick={handleRingDevice}
          size={buttonSize}
        />
      </div>
      <div className={classNames(styles.infoBoxGroup, sizeStyle)}>
        <InfoBox
          className={styles.infoBox}
          description={getPodBoxDescription()}
          flavorHue={hasFlavorHue && colors?.[0]}
          heading={getPodBoxHeading()}
          loading={loading}
          subHeading={hasAuthenticPod && {
            message: podBox.subHeading,
            values: { podStrength: podStrength || UNDEFINED_PUFF_GAUGE_VALUE },
          }}
        >
          <PuffGauge
            error={hasPodError}
            flavorHue={hasFlavorHue && colors}
            liquidLevel={hasAuthenticPod && liquidLevel}
            loading={loading}
          />
        </InfoBox>
        <InfoBox
          className={styles.infoBox}
          description={puffsOnCharge?.status === EstimateStatus.Calculated
            ? {
              message: batteryBox.description.withEstimate,
              values: { estimatedPuffsOnCharge: puffsOnCharge.estimate },
            }
            : { message: batteryBox.description.withoutEstimate }
          }
          heading={batteryBox.heading}
          loading={loading}
          subHeading={{
            message: batteryBox.subHeading,
            values: {
              batteryLevel: isNumber(batteryLevel)
                ? batteryLevel
                : UNDEFINED_BATTERY_GAUGE_VALUE,
            },
          }}
        >
          <BatteryGauge
            charging={charging}
            batteryLevel={batteryLevel}
            loading={loading}
          />
        </InfoBox>
      </div>
    </div>
  )
}
