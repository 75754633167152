import React, { FC } from 'react'
import { useFormik } from 'formik'
import { object, string } from 'yup'
import { bugsnagClient } from 'utils/bugsnag'
import { Text } from 'components/Text'
import { Textarea } from 'components/Textarea'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Tile, TileProps } from '../../../components/Tile'
import styles from './FeedbackForm.module.scss'
import { StarRating } from 'components/StarRating'
import { ThankYou } from '../ThankYou'
import { Icon } from '@juullabs/react-components'
import { WebAppFeedback } from 'analytics-events'
import { classNames } from 'utils/css'
import {
  MAX_RESPONSE_LENGTH,
  feedbackPrompt,
  inputFields,
  submissionInfo,
  submissionButton,
} from './data'

export type StarRatingForm = {
  negativeFeedback: string
  positiveFeedback: string
  stars: number
}

export interface FeedbackFormProps extends TileProps {
  isSubmitted: boolean
  onSubmitFeedback(values: StarRatingForm): void
  promptType?: WebAppFeedback.FeedbackPrompt
}

export const FeedbackForm: FC<FeedbackFormProps> = ({
  className,
  isSubmitted,
  onSubmitFeedback,
  promptType = WebAppFeedback.FeedbackPrompt.Default,
  ...rest
}) => {
  const formId = 'star-feedback-form'

  const formik = useFormik({
    initialValues: {
      negativeFeedback: null,
      positiveFeedback: null,
      stars: null,
    },
    onSubmit: async(values: StarRatingForm, { setSubmitting }) => {
      try {
        await onSubmitFeedback(values)
      } catch (e) {
        bugsnagClient?.notify?.({
          message: `${e}`,
          name: 'submit_star_feedback_failed',
        })
      } finally {
        setSubmitting(false)
      }
    },
    validationSchema: object({ stars: string().required() }),
  })

  const {
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
  } = formik

  const handleStarRating = (rating: number) => {
    setFieldValue('stars', rating)
  }

  return (
    <Tile className={classNames(styles.FeedbackForm, className)} {...rest}>
      <div className={styles.formWrap}>
        <form
          className={classNames(
            styles.formGrid,
            isSubmitted && styles.isSubmitted,
          )}
          id={formId}
          onSubmit={handleSubmit}
        >
          <Box align='center' className={styles.starRatingArea}>
            <Text
              className={styles.titlePrompt}
              align='center'
              color='ink|base|100'
              message={feedbackPrompt[promptType]}
              typography='h4Mobile'
              margin={{ bottom: 'baseLoose' }}
            />
            <StarRating onSelectRating={handleStarRating} />
          </Box>
          <Box className={styles.inputFieldArea}>
            {inputFields.map((inputField) => (
              <Box
                key={inputField.name}
                className={styles.inputField}
                align='center'
              >
                <Box margin={{ right: 'baseTight' }}>
                  <Icon className={styles.icon} {...inputField.icon} />
                </Box>
                <Textarea
                  maxLength={MAX_RESPONSE_LENGTH}
                  className={styles.textareaWrapper}
                  textareaClassName={styles.textareaInput}
                  label={(
                    <Text
                      color='ink|base|50'
                      message={inputField.prompt}
                      type='inline'
                      typography='bodySmall'
                    />
                  )}
                  name={inputField.name}
                  onChange={handleChange}
                />
              </Box>
            ))}
          </Box>
          <Box align='center' className={styles.submissionArea}>
            <Text
              align='center'
              color='ink|base|50'
              margin={{ bottom: 'tight' }}
              message={submissionInfo}
              typography='captionBase'
            />
            <Button
              fullWidth
              primary='black'
              content={submissionButton}
              disabled={!isValid || !dirty}
              loading={isSubmitting}
            />
          </Box>
        </form>
        {isSubmitted && (
          <Box className={styles.submittedOverlay}>
            <ThankYou />
          </Box>
        )}
      </div>
    </Tile>
  )
}
