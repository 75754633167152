import React, { FC, ComponentPropsWithoutRef, useRef, useEffect, useState } from 'react'
import isNumber from 'lodash/fp/isNumber'
import styles from './PuffGauge.module.scss'
import { LoadingPlaceholder } from 'components/LoadingPlaceholder'
import { classNames } from 'utils/css'

export interface PuffGaugeProps extends ComponentPropsWithoutRef<'div'> {
  /** when it isn't possible to determine the gauge state */
  error?: boolean
  /** an array of the one or two colors that define the pod flavor */
  flavorHue?: string[]
  /** boolean to turn off width recalculation if the element doesn't need to resize */
  hasFixedWidth?: boolean
  /** the amount of liquid remaining 0-100 */
  liquidLevel?: number
  /** loading state */
  loading?: boolean
}

enum ColorConfig {
  DEFAULT_HUE = 'rgba(32, 43, 58, 0.75)', // titanium|base|75
  ERROR = 'rgba(251, 87, 107, 1)', // rose|base|100
  LOADING = 'rgba(32, 43, 58, 0.3)', // titanium|base|30
  NO_DATA = 'rgba(32, 43, 58, 0.15)', // titanium|base|15
}

enum GaugeConfig {
  WIDTH_DIFFERENCE_PX = 5,
  ZERO_STATE_WIDTH_PX = 9,
}

export const PuffGauge: FC<PuffGaugeProps> = ({
  error = false,
  flavorHue,
  hasFixedWidth = false,
  liquidLevel,
  loading = false,
  ...rest
}) => {
  const puffGaugeRef = useRef<(HTMLDivElement)>()
  const hasScale = !loading && !error
  const noData = !isNumber(liquidLevel)
  const [gaugeWidth, setGaugeWidth] = useState(null)

  const getProgWidth = (width: number, offsetWidthPX: number): number => {
    // for animation return 100 otherwise just return
    if (!offsetWidthPX) return 100

    const minWidth = (GaugeConfig.ZERO_STATE_WIDTH_PX /
      (offsetWidthPX - GaugeConfig.WIDTH_DIFFERENCE_PX)) * 100
    const correction = (100 - minWidth) / 100

    if (width <= 0) return minWidth
    else if (width >= 100) return 100
    else return minWidth + (width * correction)
  }

  // todo: reconfigure, try having getProgWidth run in the use effect instead,
  // could help give more control over animation

  useEffect(() => {
    const handleGaugeWidth = () => {
      setGaugeWidth(puffGaugeRef.current?.offsetWidth)
    }

    handleGaugeWidth()

    if (!hasFixedWidth) {
      window.addEventListener('resize', handleGaugeWidth)
    }

    return () => {
      window.removeEventListener('resize', handleGaugeWidth)
    }
  }, [hasFixedWidth])

  const getBackground = () => {
    if (loading) return ColorConfig.LOADING
    else if (error) return ColorConfig.ERROR
    else if (noData) return ColorConfig.NO_DATA
    else if (!flavorHue) return ColorConfig.DEFAULT_HUE
    else return `linear-gradient(to right, ${flavorHue[0]}, ${flavorHue[1]})`
  }
  const background = getBackground()

  const getFoundationMarkup = () => {
    if (loading) {
      return <LoadingPlaceholder className={styles.loader} />
    } else if (error) {
      return <div className={styles.errorBar} />
    } else {
      return Array.from({ length: 7 }, (_, i) => i+1)
        .map((gaugeSection) => gaugeSection % 2 ? (
          <div key={gaugeSection} className={styles.spacer} />
        ) : (
          <div
            key={gaugeSection}
            className={classNames(
              styles.bottomScaleTick,
              noData && styles.noData,
            )}
          />
        ))
    }
  }

  return (
    <div
      className={styles.PuffGauge}
      ref={puffGaugeRef}
      role='presentation'
      style={{ background: background }}
      {...rest}
    >
      <div className={classNames(
        styles.innerBorder,
        hasScale && styles.hasScale,
      )}>
        {getFoundationMarkup()}
      </div>
      {hasScale && !noData && (
        <div className={styles.progressBarWrap}>
          <div
            className={styles.progressBarIndicator}
            style={{
              width: `${getProgWidth(
                liquidLevel,
                gaugeWidth,
              )}%`,
            }}>
            <div
              className={styles.progressBarFill}
              style={{ background: background }}
            />
            <div className={styles.topScale}>
              {Array.from({ length: 3 }, (_, i) => i+1).map((topScaleTick) => (
                <div key={topScaleTick} className={styles.topScaleTick} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
