import React, { FC } from 'react'
import { Section } from '../components/Section'
import { PuffsThisWeek } from './components/PuffsThisWeek'
import { PodsThisWeek } from './components/PodsThisWeek'
import { InsightsPending } from './components/InsightsPending'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import Routes from 'types/routes'

export const Insights: FC = () => {
  const { usageInsightsState, isUsageInsightsReady } = useDeviceConnection()
  const insightsLoading = !isUsageInsightsReady
  const { hasWeekOfData, daysTillWeek } = usageInsightsState || {}

  return (
    <Section
      heading={{ content: 'Usage Insights' }}
      link={{ href: Routes.UsageInsights, local: true }}
    >
      <PuffsThisWeek loading={insightsLoading} />
      {hasWeekOfData ? (
        <PodsThisWeek loading={insightsLoading} />
      ) : (
        <InsightsPending
          loading={insightsLoading}
          daysTillResolved={daysTillWeek} />
      )}
    </Section>
  )
}
