import React, {
  Children,
  ComponentPropsWithoutRef,
  createContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useRef,
} from 'react'
import { useIsomorphicLayoutEffect, useSize, useThrottledValue } from 'hooks'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'

import styles from './DeviceCarousel.module.scss'
import { classNames } from 'utils/css'

import { Button } from 'components/Button'
import { OverlayButton } from './components/OverlayButton'
import { Pagination, PaginationProps } from './components/Pagination'

export interface DeviceCarouselProps extends ComponentPropsWithoutRef<'div'> {
  /** navigation and pagination aria-labels for screen readers */
  accessibilityLabels?: {
    /** next slide button label */
    nextButton: string
    /** prefix for pagination labels */
    paginationPrefix: string
    /** previous slide button label*/
    prevButton: string
  }
  /** allow ability to swipe though with mouse or by touch */
  allowTouchMove?: boolean
  /** elements in the carousel */
  children?: ReactNode
  /** custom className */
  className?: string
  /** pagination button style:
   *  dots are good for desktop, where dashes are better for touch screens */
  paginationStyle?: PaginationProps['paginationStyle']
  /** when true the carousel view window includes a hint of the adjacent slides,
   * which then double as buttons */
  peepAdjacent?: boolean
  /** represents the space between two slides [Note: when peepAdjacent is true,
   * the visible width of the adjacent slides will be equal to the space between slides] */
  spaceBetween?: number
  /** carousel transition speed in MS */
  speed?: number
}

type DeviceCarouselSlideContextType = {
  isInactive: boolean
}

/**
 * todo: Option to replace DeviceCarouselSlideContext.Provider with
 * useSwiperSlide hook from swiper now that we are on swiper 11+.
 * ...Although provider method still works quite well.
 */
export const DeviceCarouselSlideContext
  = createContext<DeviceCarouselSlideContextType>({ isInactive: false })

const DEFAULT_ACCESSIBILITY_LABELS:
  DeviceCarouselProps['accessibilityLabels'] = {
    nextButton: 'next slide',
    paginationPrefix: 'slide',
    prevButton: 'previous slide',
  }

export const DeviceCarousel: FC<DeviceCarouselProps> = ({
  accessibilityLabels = DEFAULT_ACCESSIBILITY_LABELS,
  allowTouchMove = false,
  children,
  className,
  paginationStyle = 'dots',
  peepAdjacent = false,
  spaceBetween = 24,
  speed = 400,
  ...rest
}) => {
  // data
  const numberOfSlides = Children.toArray(children).length
  const totalMargin = 4 * spaceBetween

  // refs
  const carouselRef = useRef<(HTMLDivElement)>()
  const [swiper, setSwiper] = useState<SwiperCore>(null)

  // state
  const [activeIndex, setActiveIndex] = useState<number>(0)
  const [carouselWidth, setCarouselWidth] = useState<number>(0)

  // resize
  const size = useSize(carouselRef)
  const throttledSize = useThrottledValue({ throttleMs: 300, value: size })

  // functions
  const getInnerCarouselStyles = () => {
    if (!peepAdjacent) return { width: '100%' }
    return {
      marginLeft: `-${totalMargin/2}px`,
      width: `calc(100% + ${totalMargin}px)`,
    }
  }

  const getSlidesPerView = () => {
    if (!peepAdjacent || !carouselWidth) return 1
    return (carouselWidth + totalMargin) / carouselWidth
  }

  const handlePrev = useCallback(() => {
    swiper?.slidePrev()
  }, [swiper])

  const handleNext = useCallback(() => {
    swiper?.slideNext()
  }, [swiper])

  const handlePagination = useCallback((slideId: number) => {
    swiper?.slideTo(slideId)
  }, [swiper])

  // effects
  useIsomorphicLayoutEffect(() => {
    if (!swiper) return

    setCarouselWidth(carouselRef?.current?.offsetWidth)
    swiper.update()

  }, [peepAdjacent, throttledSize?.width, Boolean(size)])

  return (
    <div
      className={classNames(styles.Carousel, className)}
      ref={carouselRef}
      {...rest}
    >
      <div
        className={styles.innerCarousel}
        style={{ ...getInnerCarouselStyles() }}
      >
        {!peepAdjacent && (
          <>
            {!swiper?.isBeginning && (
              <Button
                accessibilityLabel={accessibilityLabels.prevButton}
                icon='icon/24x24/chevron_large_left'
                className={styles.leftArrow}
                onClick={handlePrev}
              />
            )}
            {!swiper?.isEnd && (
              <Button
                accessibilityLabel={accessibilityLabels.nextButton}
                icon='icon/24x24/chevron_large_right'
                className={styles.rightArrow}
                onClick={handleNext}
              />
            )}
          </>
        )}
        <Swiper
          onSwiper={setSwiper}
          onActiveIndexChange={swiper => setActiveIndex(swiper?.activeIndex)}
          slidesPerView={getSlidesPerView()}
          spaceBetween={spaceBetween}
          centeredSlides={true}
          speed={speed}
          allowTouchMove={allowTouchMove}
        >
          {Children.map(children, child => (
            <SwiperSlide className={styles.carouselSlide}>
              {({ isActive, isNext, isPrev }) => (
                <>
                  {peepAdjacent && (
                    <>
                      {isPrev && (
                        <OverlayButton
                          accessibilityLabel={accessibilityLabels.prevButton}
                          extendedAreaDirection='right'
                          extendedAreaWidth={spaceBetween}
                          onClick={handlePrev}
                        />
                      )}
                      {isNext && (
                        <OverlayButton
                          accessibilityLabel={accessibilityLabels.nextButton}
                          extendedAreaDirection='left'
                          extendedAreaWidth={spaceBetween}
                          onClick={handleNext}
                        />
                      )}
                    </>
                  )}
                  <DeviceCarouselSlideContext.Provider
                    value={{ isInactive: !isActive }}
                  >
                    {child}
                  </DeviceCarouselSlideContext.Provider>
                </>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {numberOfSlides > 1 && (
        <Pagination
          accessibilityLabelPrefix={accessibilityLabels.paginationPrefix}
          activeIndex={activeIndex}
          handlePagination={handlePagination}
          numberOfSlides={numberOfSlides}
          paginationStyle={paginationStyle}
        />
      )}
    </div>
  )
}
