import { ComponentProps } from 'react'
import { Icon } from '@juullabs/react-components'
import { TextFormats } from 'components/Text'
import { WebAppFeedback } from 'analytics-events'

type InputField = {
  icon: ComponentProps<typeof Icon>
  name: string
  prompt: TextFormats
};

// max length of feedback response
export const MAX_RESPONSE_LENGTH = 1024

// textarea input fields for feedback form
export const inputFields: InputField[] = [
  {
    icon: { name: 'icon/24x24/face_happy_outlined' },
    name: 'positiveFeedback',
    prompt: { content: 'What do you like? (Optional)' },
  },
  {
    icon: { name: 'icon/24x24/face_neutral_outlined' },
    name: 'negativeFeedback',
    prompt: { content: 'What should we change or add? (Optional)' },
  },
]

// feedback prompts for feedback form
export const feedbackPrompt: Record<WebAppFeedback.FeedbackPrompt, TextFormats> = {
  currentVersion: { content: 'How do you like this version of the JUUL app?' },
  default: { content: 'How do you like the app?' },
}

// feedback submission data
export const submissionInfo: TextFormats = { content: 'Enter a star value to submit.' }
export const submissionButton = 'Submit'
