import React, { FC } from 'react'
import { useRouter } from 'next/router'
import Routes from 'types/routes'
import { Section } from '../components/Section'
import { DeviceCarousel } from './components/DeviceCarousel'
import { NewDeviceCard } from './components/NewDeviceCard'
import { useDeviceConnection } from 'modules/DeviceConnectionProvider'
import { useConxSdk } from 'modules/ConxSdkProvider'
import { DeviceCard } from './components/DeviceCard/DeviceCard'
import { DeviceCardSizes, DeviceCardViews } from './types'
import { useMediaQuery } from 'hooks/use-media-query'
import { getInterfaceType } from 'utils/gadget'

// responsive data
const MEDIA_QUERIES = [
  '(min-width: 915px)',
  '(min-width: 769px)',
  '(min-width: 481px)',
  '(min-width: 388px)',
]
const HOME_VIEWS: DeviceCardViews[] = [
  { size: 5 },
  { size: 4 },
  { size: 3 },
  { size: 2 },
]
const DEFAULT_HOME_VIEW: DeviceCardViews = { size: 1 }

const SLIDE_GAP = {
  default: 24,
  narrow: 8,
}

export const Devices: FC = () => {
  const router = useRouter()
  const { gadgetsList } = useDeviceConnection()
  const { userAgent } = useConxSdk()

  const { size } = useMediaQuery<{ size: DeviceCardSizes }>(
    MEDIA_QUERIES,
    HOME_VIEWS,
    DEFAULT_HOME_VIEW,
  )
  const isNarrowView = size <= 2

  return (
    <Section heading={{ content: 'My Devices' }}>
      <DeviceCarousel
        paginationStyle={userAgent?.isDesktop ? 'dots' : 'dashes'}
        allowTouchMove={!userAgent?.isDesktop}
        peepAdjacent={isNarrowView}
        spaceBetween={isNarrowView ? SLIDE_GAP.narrow : SLIDE_GAP.default}
      >
        {gadgetsList.map(({
          beepable,
          connectable,
          connectionStatus,
          hardwareId,
          insecurelyConnected,
          juulInfo,
          lastConnectedAt,
          lockable,
          podInfo,
          reauthenticate,
        }) => (
          <DeviceCard
            key={'gadget-' + hardwareId}
            batteryLevel={juulInfo?.batteryLevel}
            charging={juulInfo?.isChargerConnected}
            deviceName={juulInfo.name}
            disconnectDevice={async() => connectable?.disconnect()}
            hasPod={juulInfo?.hasPod}
            interfaceType={getInterfaceType(connectionStatus, insecurelyConnected)}
            lastConnected={lastConnectedAt}
            liquidLevel={podInfo?.usage}
            lockDevice={async() => lockable?.lock()}
            locked={juulInfo?.isLocked}
            podStatus={podInfo?.status}
            puffsOnCharge={juulInfo?.batteryEstimate}
            puffsOnPod={podInfo?.podConsumptionEstimate}
            reauthenticateDevice={async() => reauthenticate()}
            recipe={podInfo?.recipe}
            reconnectDevice={async() => connectable?.connect()}
            ringDevice={async() => beepable?.beep()}
            serialNumber={juulInfo.serialNumber}
            size={size}
            unlockDevice={async() => lockable?.unlock()}
          />
        ))}
        <NewDeviceCard
          connectNewDevice={() => router.push(Routes.Pairing)}
          size={size}
        />
      </DeviceCarousel>
    </Section>
  )
}
