import React, { FC } from 'react'
import styles from './Reconnecting.module.scss'
import { Text } from 'components/Text'
import { DeviceButton } from '../DeviceButton'
import { Spinner } from 'components/Spinner'
import { reconnectingData } from './localization'
import { DeviceCardChild } from '../../types'

export interface ReconnectingProps extends DeviceCardChild {
  /** action for the button on the reconnecting card state */
  onClick(): void
}

export const Reconnecting: FC<ReconnectingProps> = ({
  inactive = false,
  onClick,
}) => {
  const { button, spinnerLabel, subtitle, title } = reconnectingData

  return (
    <>
      <div>
        <Spinner
          accessibilityLabel={spinnerLabel}
          circleClassName={styles.spinnerCircle}
          className={styles.spinner}
          size='base'
        />
        <Text
          color='titanium|base|100'
          margin={{ top: 'baseTight' }}
          message={title}
          tagName='p'
          typography='bodyBaseBold'
        />
        <Text
          color='titanium|base|100'
          margin={{ top: 'extremeTight' }}
          message={subtitle}
          tagName='p'
          typography='bodySmall'
        />
      </div>
      <DeviceButton
        content={button}
        disabled={inactive}
        onClick={onClick}
        outline
        size='small'
      />
    </>
  )
}
