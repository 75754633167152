import { TextFormats } from 'components/Text'

export type ReconnectingData = {
  button: string
  spinnerLabel: string
  subtitle: TextFormats
  title: TextFormats
}

export const reconnectingData: ReconnectingData = {
  button: 'Cancel',
  spinnerLabel: 'Reconnecting',
  subtitle: { content: 'Make sure your device is charged and nearby.' },
  title: { content: 'Attempting to reconnect...' },
}
