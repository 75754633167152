import { TextFormats } from 'components/Text'

export type DisconnectedData = {
  alert: TextFormats
  button: string
  caption: TextFormats
}

export const disconnectedData: DisconnectedData = {
  alert: { content: 'This device is in danger of losing your data. Please reconnect to sync your usage data.' },
  button: 'Reconnect',
  caption: { content: 'Last connected %{timeSinceConnected}.' },
}
