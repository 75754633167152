import { TextFormats } from 'components/Text'

export type UnauthenticatedData = {
  button: string
  subtitle: TextFormats
  title: TextFormats
}

export const unauthenticatedData: UnauthenticatedData = {
  button: 'Confirm connection',
  subtitle: { content: 'Your JUUL2 Device will disconnect from any other JUUL apps.' },
  title: { content: 'Re-authenticate connection.' },
}
