import { TextFormats } from 'components/Text'

//// enums

export enum DeviceButtonIcons {
  Lock='icon/16x16/locked_standard_filled',
  Ring='icon/16x16/ring_my_device',
  Unlock='icon/16x16/unlocked_partial_filled',
}

//// types

type Description = {
  lowGauge?: TextFormats
  withEstimate: TextFormats
  withoutEstimate: TextFormats
}

type BatteryBox = {
  description: Description
  heading: TextFormats
  subHeading: TextFormats
}

type PodBoxHeading = {
  flavorUnavailable: string
  podError: string
  withoutPod: string
}

type PodBox = {
  description: Description
  heading: PodBoxHeading
  subHeading: TextFormats
}

type ConnectedData = {
  batteryBox: BatteryBox
  lockButton: string
  podBox: PodBox
  ringButton: string
  unlockButton: string
}

//// data

const batteryBoxData: BatteryBox = {
  description: {
    withEstimate: { content: 'Estimated: %{estimatedPuffsOnCharge} puffs left on charge.' },
    withoutEstimate: { content: 'Not enough data for est. puffs on charge.' },
  },
  heading: { content: 'Battery ' },
  subHeading: { content: '%{batteryLevel}%' },
}

const podBoxHeading: PodBoxHeading = {
  flavorUnavailable: 'Flavor unavailable',
  podError: 'Pod error',
  withoutPod: 'No JUUL2 pod detected',
}

const podBoxData: PodBox = {
  description: {
    lowGauge: { content: 'Estimated: Very low pod e‑liquid level.' },
    withEstimate: { content: 'Estimated: %{estimatedPuffsOnPod} puffs left on pod.' },
    withoutEstimate: { content: 'Not enough data for est. puffs on pod.' },
  },
  heading: podBoxHeading,
  subHeading: { content: '%{podStrength} JUUL2 pod' },
}

export const connectedData: ConnectedData = {
  batteryBox: batteryBoxData,
  lockButton: 'Lock',
  podBox: podBoxData,
  ringButton: 'Ring',
  unlockButton: 'Unlock',
}
