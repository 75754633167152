import React, { FC, HTMLAttributes, useState, useCallback } from 'react'
import { classNames } from 'utils/css'
import styles from './StarRating.module.scss'
import { Icon } from '@juullabs/react-components'

export interface StarRatingProps extends HTMLAttributes<HTMLFieldSetElement> {
  /** additional styles */
  className?: string
  /** number of buttons */
  numberOfStars?: number
  /** callback to capture rating */
  onSelectRating(rating: number): void
}

export const StarRating: FC<StarRatingProps> = ({
  className,
  numberOfStars = 5,
  onSelectRating,
  ...rest
}) => {
  const [rating, setRating] = useState<number>(null)
  const [hover, setHover] = useState<number>(null)

  const handleRating = useCallback((starRating: number) => {
    setRating(starRating)
    onSelectRating(starRating)
  }, [onSelectRating])

  return (
    <fieldset className={classNames(styles.StarRating, className)} {...rest}>
      <div className={styles.stars}>
        {Array.from({ length: numberOfStars }, (_, i) => i+1)
          .map((starRating) => {
            const isFilled = starRating <= (hover || rating)

            return (
              <div className={styles.star} key={starRating + '-stars'}>
                <input
                  className={styles.starInput}
                  id={`${starRating}-stars`}
                  name='star-rating'
                  onChange={() => handleRating(starRating)}
                  type='radio'
                  value={starRating}
                />
                <label
                  aria-label={`${starRating}-stars`}
                  className={styles.starLabel}
                  htmlFor={`${starRating}-stars`}
                  onMouseEnter={() => setHover(starRating)}
                  onMouseLeave={() => setHover(null)}
                >
                  <Icon
                    className={styles.starIcon}
                    color={isFilled ? 'blue|base|100' : 'steel|base|100'}
                    name={isFilled
                      ? 'icon/24x24/star_base_filled'
                      : 'icon/24x24/star_base_outlined'}
                  />
                </label>
              </div>
            )
          })}
      </div>
    </fieldset>
  )
}
