import { Subscription, Order } from 'modules/MobilecloudAPI'

export enum FetchStatus {
  ERROR='error',
  LOADING='loading',
  NOT_FOUND='not-found',
  SUCCESS='success',
}

export type MostRecentOrderState = {
  payload?: Order
  status: FetchStatus
}

export type SubscriptionState = {
  payload?: Subscription
  status: FetchStatus
}
