import React, { FC } from 'react'
import styles from './DeviceButton.module.scss'
import { classNames, variationName } from 'utils/css'
import { Icon } from '@juullabs/react-components'
import { UnstyledButton, UnstyledButtonProps } from 'components/UnstyledPrimitives'
import { Spinner } from 'components/Spinner'

export interface DeviceButtonProps extends UnstyledButtonProps {
  /** Visually hidden text for screen readers */
  accessibilityLabel?: string
  /** Additional classnames */
  className?: string
  /** Optional theme color */
  color?: string
  /** Button text */
  content: string
  /** Disables the button, disallowing interaction */
  disabled?: boolean
  /** Allows the button to grow to the width of its container */
  fullWidth?: boolean
  /** An Icon to be displayed in the button */
  icon?: string
  /**
   * Replaces button text with a spinner while a background action is being
   * performed.
   */
  loading?: boolean
  /**
   * Gives the button a subtle alternative to the default button styling,
   * appropriate for certain backdrops.
   */
  outline?: boolean
  /**
   * Changes the size of the button, giving it more or less padding
   * @default base
   */
  size?: 'small' | 'base'
}

export const DeviceButton: FC<DeviceButtonProps> = ({
  accessibilityLabel,
  color,
  className,
  content,
  disabled,
  fullWidth,
  icon,
  loading,
  outline,
  size = 'base',
  ...rest
}) => {
  const hasColorTheme = color && !disabled

  const getIconColor = () => {
    if (disabled && outline && !color) {
      return 'titanium|base|50'
    } else if (disabled) {
      return 'milk|base|75'
    } else if (outline && !color) {
      return 'titanium|base|100'
    } else {
      return 'milk|base|100'
    }
  }

  return (
    <UnstyledButton
      aria-busy={loading}
      aria-label={accessibilityLabel}
      className={classNames(
        styles.DeviceButton,
        color && styles.color,
        outline && styles.outline,
        fullWidth && styles.fullWidth,
        size && styles[variationName('size', size)],
        className,
      )}
      disabled={disabled || loading}
      style={hasColorTheme ? { backgroundColor: color } : null}
      {...rest}
    >
      {hasColorTheme && <div className={styles.colorMask}/>}
      {loading && (
        <Spinner
          size={'small'}
          circleClassName={outline ? styles.darkSpinner : styles.lightSpinner}
          className={styles.loadingSpinner}
        />
      )}
      <div className={classNames(
        styles.content,
        loading && styles.hideContent,
      )}>
        {icon && (
          <Icon
            className={styles.icon}
            name={icon}
            color={getIconColor()}
          />
        )}
        {content && <span>{content}</span>}
      </div>
    </UnstyledButton>
  )
}
