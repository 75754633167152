import React, { FC } from 'react'
import styles from './OverlayButton.module.scss'
import { classNames } from 'utils/css'
import { UnstyledButton, UnstyledButtonProps } from 'components/UnstyledPrimitives'

export interface OverlayButtonProps extends UnstyledButtonProps {
  /** Visually hidden text for screen readers */
  accessibilityLabel?: string
  /** The direction in which the clickable area adjacent to the button extends */
  extendedAreaDirection: 'left' | 'right'
  /** A number representing the width in pixels of the extended clickable area
   * adjacent to the button */
  extendedAreaWidth: number
}

export const OverlayButton: FC<OverlayButtonProps> = ({
  accessibilityLabel,
  className,
  extendedAreaDirection,
  extendedAreaWidth,
  onClick,
  ...rest
}) => (
  <UnstyledButton
    aria-label={accessibilityLabel}
    className={classNames(styles.OverlayButton, className)}
    onClick={onClick}
    {...rest}
  >
    <div
      className={styles.extension}
      style={{
        [extendedAreaDirection]: `-${extendedAreaWidth}px`,
        width: `${extendedAreaWidth * 2}px`,
      }}
    />
  </UnstyledButton>
)
