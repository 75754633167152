import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './DeviceHandle.module.scss'
import { classNames, variationName } from 'utils/css'
import { Icon } from '@juullabs/react-components'

export interface DeviceHandleProps extends ComponentPropsWithoutRef<'div'> {
  /** custom className */
  className?: string
  /** users device name (primary text) */
  heading: string
  /** heading size, small: mobile and small screen, base: default */
  headingTextSize?: 'base' | 'small'
  /** is the device locked, when locked, locked icon appears next to heading */
  locked?: boolean
  /** when masked the handle opacity/visibility is decreased */
  masked?: boolean
  /** users device Serial number (secondary text) */
  subHeading: string
  /** color theme of the text */
  textColor?: string
}

const LOCK_ICON = 'icon/16x16/lock_red_small'

export const DeviceHandle: FC<DeviceHandleProps> = ({
  className,
  heading,
  headingTextSize = 'base',
  locked = false,
  masked = false,
  subHeading,
  textColor,
  ...rest
}) => {
  const useTextColor = textColor && !locked && !masked
  const backgroundColor = useTextColor ? textColor : 'transparent'

  return (
    <header className={classNames(styles.DeviceHandle, className)} {...rest}>
      <div className={classNames(
        styles.heading,
        masked && styles.masked,
        styles[variationName('size', headingTextSize)],
      )}>
        <h3
          className={classNames(
            styles.headingText,
            useTextColor && styles.headingTextColor,
          )}
          style={{ backgroundColor: backgroundColor }}
        >
          {heading}
        </h3>
        {locked && (
          <Icon
            className={styles.lockIcon}
            name={LOCK_ICON}
            color={masked ? 'red|dark|50' : 'red|dark|100'}
          />
        )}
      </div>
      <div className={classNames(
        styles.subHeading,
        masked && styles.masked,
      )}>
        <h4
          className={classNames(
            styles.subHeadingText,
            useTextColor && styles.subHeadingTextColor,
          )}
          style={{ backgroundColor: backgroundColor }}
        >
          {subHeading}
        </h4>
      </div>
    </header>
  )
}
