import React, { ComponentPropsWithoutRef, FC } from 'react'
import styles from './DeviceCard.module.scss'
import { classNames, variationSize } from 'utils/css'
import { DeviceHandle } from '../DeviceHandle'
import { BackgroundTile } from '../BackgroundTile'
import { DeviceHero } from '../DeviceHero'
import { useDeviceCarouselSlide } from '../DeviceCarousel'
import { Reconnecting } from '../Reconnecting'
import { Disconnected } from '../Disconnected'
import { Unauthenticated } from '../Unauthenticated'
import { Connected } from '../Connected'
import { DeviceCardSizes } from '../../types'
import Chai from 'types/chai'
import { getGadgetAction } from 'utils/gadget'
import { InterfaceType } from 'types/gadget'

export interface DeviceCardProps extends ComponentPropsWithoutRef<'div'> {
  /** the amount of battery remaining 0-100 */
  batteryLevel?: number
  /** whether the batteries are charging */
  charging?: boolean
  /** additional classnames */
  className?: string
  /** user chosen device name */
  deviceName: string
  /** callback to disconnect device */
  disconnectDevice?(): void
  /** if the pod is connected or disconnected */
  hasPod?: boolean
  /** view the user will see (connected/reconnecting/disconnected/unauthenticated) */
  interfaceType: InterfaceType
  /** UTC timestamp of last device sync event */
  lastConnected: string
  /** the amount of liquid remaining 0-100 */
  liquidLevel?: number
  /** is content loading */
  loading?: boolean
  /** callback to lock users device */
  lockDevice?(): void
  /** is the device locked */
  locked?: boolean
  /** pod statuses */
  podStatus?: Chai.PodStatus
  /** estimate of the puffs remaining on a users battery */
  puffsOnCharge?: Chai.UsageEstimate
  /** estimate of the puffs remaining in a users pod */
  puffsOnPod?: Chai.UsageEstimate
  /** callback to reauthenticate device */
  reauthenticateDevice?(): void
  /** pod recipe */
  recipe?: Chai.Recipe
  /** callback to reconnect device */
  reconnectDevice?(): void
  /** callback to ring the users device */
  ringDevice?(): void
  /** device's serial number */
  serialNumber: string
  /** delineates the size and version of the DeviceCard */
  size: DeviceCardSizes
  /** callback to unlock users device */
  unlockDevice?(): void
}

export const DeviceCard: FC<DeviceCardProps> = ({
  batteryLevel,
  charging,
  className,
  deviceName,
  disconnectDevice,
  hasPod,
  interfaceType,
  lastConnected,
  liquidLevel,
  loading = false,
  lockDevice,
  locked,
  podStatus,
  puffsOnCharge,
  puffsOnPod,
  reauthenticateDevice,
  recipe,
  reconnectDevice,
  ringDevice,
  serialNumber,
  size,
  unlockDevice,
  ...rest
}) => {
  const { isInactive } = useDeviceCarouselSlide()
  const masked = interfaceType !== InterfaceType.Connected || loading
  const colors = recipe?.gradientColors
  const useColor = !locked && !masked && hasPod

  return (
    <article
      className={classNames(
        styles.DeviceDash,
        styles[variationSize('size', size)],
        className,
      )}
      {...rest}
    >
      {size >= 4 && (
        <DeviceHero
          borderColor={colors?.[0]}
          className={styles.deviceHero}
          hasPod={hasPod && podStatus?.isPodPresent}
          locked={locked}
          masked={masked}
        />
      )}
      <BackgroundTile
        className={styles.backgroundTile}
        colors={useColor && colors}
        size={size}
      >
        <DeviceHandle
          heading={deviceName}
          headingTextSize={size <= 2 ? 'small' : 'base'}
          locked={locked}
          masked={masked}
          subHeading={serialNumber}
          textColor={hasPod && colors?.[0]}
        />
        {(() => {
          switch (interfaceType) {
          case InterfaceType.Connected:
            return (
              <Connected
                batteryLevel={batteryLevel}
                charging={charging}
                colors={colors}
                hasPod={hasPod}
                liquidLevel={liquidLevel}
                loading={loading}
                lockDevice={getGadgetAction(lockDevice, 'lock')}
                locked={locked}
                inactive={isInactive}
                podFlavor={recipe?.flavorName}
                podStatus={podStatus}
                podStrength={recipe?.strength}
                puffsOnCharge={puffsOnCharge}
                puffsOnPod={puffsOnPod}
                ringDevice={getGadgetAction(ringDevice, 'beep')}
                size={size}
                unlockDevice={getGadgetAction(unlockDevice, 'unlock')}
              />
            )
          case InterfaceType.Disconnected:
            return (
              <Disconnected
                inactive={isInactive}
                lastConnected={lastConnected}
                onClick={getGadgetAction(reconnectDevice, 'connect')}
              />
            )
          case InterfaceType.Reconnecting:
            return (
              <Reconnecting
                inactive={isInactive}
                onClick={getGadgetAction(disconnectDevice, 'disconnect')}
              />
            )
          case InterfaceType.Unauthenticated:
            return (
              <Unauthenticated
                inactive={isInactive}
                onClick={getGadgetAction(reauthenticateDevice, 'reauthenticate')}
              />
            )
          }
        })()}
      </BackgroundTile>
    </article>
  )
}
