import React, { FC, ComponentPropsWithoutRef } from 'react'
import styles from './Section.module.scss'
import { Text, TextFormats } from 'components/Text'
import { Icon } from '@juullabs/react-components'
import { UnstyledLink, UnstyledLinkProps } from 'components/UnstyledPrimitives'
import { classNames } from 'utils/css'

export interface SectionProps extends ComponentPropsWithoutRef<'div'> {
  heading: TextFormats
  link?: UnstyledLinkProps
}

const SectionLink: FC<UnstyledLinkProps> = ({
  disabled,
  ...rest
}) => (
  <UnstyledLink className={classNames(
    styles.SectionLink,
    disabled && styles.disabled,
  )} {...rest}>
    <span>view all</span>
    <Icon
      className={styles.linkIcon}
      color={disabled ? 'steel|base|30' : 'ink|base|50'}
      name='icon/24x24/chevron_base_right'
    />
  </UnstyledLink>
)

export const Section: FC<SectionProps> = ({
  className,
  children,
  link,
  heading,
  ...rest
}) => (
  <section className={classNames(styles.Section, className)} {...rest}>
    <header className={styles.header}>
      <Text
        className={styles.heading}
        message={heading}
        tagName='h2'
      />
      {link && <SectionLink {...link} />}
    </header>
    <div className={styles.body}>
      {children}
    </div>
  </section>
)
