import React, { FC } from 'react'
import styles from './AutoShipCTA.module.scss'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Text } from 'components/Text'
import { Icon } from '@juullabs/react-components'
import { LinkButton } from 'components/Button'
import { replaceBreakingChars } from 'utils/text'
import { MobilecloudLink } from 'modules/MobilecloudAPI/links'
import Locales from 'types/locales'

const heading = {
  [Locales.EN_GB]: 'Subscription',
  default: 'Auto‑Ship',
}

export interface AutoShipCTAProps extends TileProps {
  locale: string
  onClick?: () => void
}

export const AutoShipCTA: FC<AutoShipCTAProps> = ({ locale, onClick }) => (
  <Tile className={styles.AutoShipCTA}>
    <div className={styles.content}>
      <Text
        color='jade|darker|100'
        message={{ content: heading[locale] || heading.default }}
        tagName='h3'
        typography='h4'
      />
      <Text
        color='jade|darker|100'
        margin={{ bottom: 'baseTight', top: 'extremeTight' }}
        message={{
          content: replaceBreakingChars('You haven\'t set up a')
            + ' '
            + replaceBreakingChars('subscription yet.'),
        }}
        tagName='p'
        typography='bodySmall'
      />
      <LinkButton
        className={styles.linkButton}
        content='Learn more'
        href={MobilecloudLink.Subscription}
        primary='jade'
        onClick={onClick}
        rel='noreferrer'
        size='small'
        target='_blank'
      />
    </div>
    <Icon
      className={styles.icon}
      name='icon/24x24/subscription'
    />
  </Tile>
)
