import React, { FC } from 'react'
import styles from './InsightsPending.module.scss'
import { Tile, TileProps } from 'modules/Home/components/Tile'
import { Text } from 'components/Text'
import { Icon } from '@juullabs/react-components'
import { formatDays } from 'modules/UsageInsights/utils'

export interface InsightsPendingProps extends TileProps {
  daysTillResolved: number
}

export const InsightsPending: FC<InsightsPendingProps> = ({
  daysTillResolved,
  ...rest
}) => (
  <Tile className={styles.InsightsPending} {...rest}>
    <div className={styles.message}>
      <Text
        color='graphica|darker|100'
        message={{ content: 'Your insights are being calculated and will be ready in:' }}
        tagName='p'
        typography='bodyBase'
      />
      <Text
        color='graphica|darker|100'
        margin={{ top: 'tight' }}
        message={{ content: '%{days} %{unit}', tagName: 'strong' }}
        tagName='p'
        typography='bodyBig'
        values={formatDays(daysTillResolved)}
      />
    </div>
    <Icon
      className={styles.icon}
      name='icon/32x32/clock'
    />
  </Tile>
)
