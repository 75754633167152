/**
 * Returns a hex color as a rgba color.
 *
 * @param hex hex code string representing a color
 * @param alpha optional alpha to changes the transparency of the hex color
 * @returns and rgba formatted color
 */
export const hex2rgba = (hex: string, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}
