import React, { FC, ComponentPropsWithoutRef, ElementType } from 'react'
import styles from './BackgroundTile.module.scss'
import { DeviceCardSizes } from '../../types'
import { hex2rgba } from 'utils/color'
import { classNames, variationSize } from 'utils/css'

export interface BackgroundTileProps extends ComponentPropsWithoutRef<ElementType> {
  /** array of two custom colors that generate a color gradient on the tile */
  colors?: string[]
  /** has drop shadow or not */
  hasShadow?: boolean
  /** delineates the size (height) of the tile,
   * important for responsive device cards */
  size?: DeviceCardSizes
  /** optional tag names for better semantics */
  tagName?: 'div' | 'article'
}

// constants
const DEFAULT_HUE = '#202B3A' //titanium-base-100
const BACKGROUND_FILTER = hex2rgba('#F1F1F1', 0.95) //snow-light-95
const BORDER_ALPHA = 0.3

export const BackgroundTile: FC<BackgroundTileProps> = ({
  children,
  className,
  colors,
  hasShadow = false,
  size = 1,
  tagName: TagName = 'div',
  ...rest
}) => {
  const themeColors = colors || [DEFAULT_HUE, DEFAULT_HUE]

  return (
    <TagName
      className={classNames(
        styles.BackgroundTile,
        styles[variationSize('size', size)],
        hasShadow && styles.hasShadow,
        className,
      )}
      style={{
        background: `
        linear-gradient(to right,
          ${BACKGROUND_FILTER},
          ${BACKGROUND_FILTER}
        ) padding-box,
        linear-gradient(to right,
          ${hex2rgba(themeColors[0])},
          ${hex2rgba(themeColors[1])}
        ) padding-box,
        linear-gradient(to right,
          ${hex2rgba(themeColors[0], BORDER_ALPHA)},
          ${hex2rgba(themeColors[1], BORDER_ALPHA)}
        ) border-box
      `,
      }}
      {...rest}
    >
      {children}
    </TagName>
  )
}
