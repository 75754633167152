import { bugsnagClient } from 'utils/bugsnag'
import { GadgetConnectionStatus, InterfaceType } from 'types/gadget'
import { getIsPreProdEnvironment } from './app'

type GadgetAction =
  | 'beep'
  | 'connect'
  | 'disableChargedBeepable'
  | 'disconnect'
  | 'enableChargedBeepable'
  | 'lock'
  | 'reauthenticate'
  | 'unlock'
  | 'unpair'

export const getGadgetAction = (
  action: () => void,
  actionType: GadgetAction,
) => (async() => {
  try {
    await action()
  } catch (error) {
    const errorType = `${actionType}_failed`
    bugsnagClient?.notify?.({ message: `${error}`, name: errorType })

    // eslint-disable-next-line no-console
    if (getIsPreProdEnvironment()) console.error(errorType, error)
  }
})

export const getInterfaceType = (
  connectionStatus: string,
  insecurelyConnected: boolean,
):InterfaceType => {
  if (insecurelyConnected && connectionStatus !== GadgetConnectionStatus.Disconnected) {
    return InterfaceType.Unauthenticated
  } else if (connectionStatus === GadgetConnectionStatus.Connected) {
    return InterfaceType.Connected
  } else if (connectionStatus === GadgetConnectionStatus.Intermediate) {
    return InterfaceType.Reconnecting
  } else {
    return InterfaceType.Disconnected
  }
}
