import React, { FC, ComponentPropsWithoutRef } from 'react'
import { Image } from 'components/Image'
import styles from './DeviceHero.module.scss'
import { classNames, variationName } from 'utils/css'
import { DeviceCardImages, DeviceCardPlaceholders } from '../../types'
import { hex2rgba } from 'utils/color'

export interface DeviceHeroProps extends ComponentPropsWithoutRef<'div'> {
  /** color theme of the line on the bottom edge of the image */
  borderColor?: string
  /** custom className */
  className?: string
  /** if the pod is connected or disconnected,
   * when disconnected the pod won't be present */
  hasPod?: boolean
  /** is the device locked, when locked the device lights glow red */
  locked?: boolean
  /** when masked the hero opacity/visibility is decreased */
  masked?: boolean
}

const DEFAULT_HUE = '#202B3A' //titanium-base-100
const BORDER_ALPHA = 0.3

export const DeviceHero: FC<DeviceHeroProps> = ({
  className,
  borderColor = DEFAULT_HUE,
  hasPod = true,
  locked = false,
  masked = false,
  ...rest
}) => {
  const showPod = hasPod || masked
  const borderHue = !locked && !masked && hasPod
    ? borderColor
    : DEFAULT_HUE

  return (
    <div
      className={classNames(
        styles.DeviceHero,
        masked && styles.masked,
        className,
      )}
      style={{ borderBottomColor: `${hex2rgba(borderHue, BORDER_ALPHA)}` }}
      {...rest}
    >
      <div className={classNames(
        styles.juulPod,
        styles[variationName('pod', showPod ? 'connected' : 'disconnected')],
      )}>
        <Image
          width={56}
          height={120}
          src={DeviceCardImages.Pod}
          alt='JUUL2 pod'
          quality={100}
          placeholder='blur'
          blurDataURL={DeviceCardPlaceholders.Pod}
        />
      </div>
      <div className={styles.juulDevice}>
        <Image
          width={56}
          height={130}
          src={locked
            ? DeviceCardImages.PartialDeviceLocked
            : DeviceCardImages.PartialDevice}
          alt={locked ? 'Locked JUUL2 Device' : 'JUUL2 Device'}
          quality={100}
          placeholder='blur'
          blurDataURL={locked
            ? DeviceCardPlaceholders.PartialDeviceLocked
            : DeviceCardPlaceholders.PartialDevice}
        />
      </div>
    </div>
  )
}
