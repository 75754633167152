import React, { FC, ComponentPropsWithoutRef } from 'react'
import { classNames } from 'utils/css'
import styles from './InfoBox.module.scss'
import { Box } from 'components/Box'
import { Text, TextFormats, ComplexMessage } from 'components/Text'

export interface InfoBoxProps extends ComponentPropsWithoutRef<'div'> {
  /** custom className */
  className?: string
  /** descriptive text */
  description?: ComplexMessage
  /** flavor color data ie #xxxxxx */
  flavorHue?: string
  /** title text */
  heading: TextFormats
  /** is content loading */
  loading?: boolean
  /** sub title text */
  subHeading?: ComplexMessage
}

export const InfoBox: FC<InfoBoxProps> = ({
  children,
  className,
  description,
  flavorHue,
  heading,
  loading = false,
  subHeading,
  ...rest
}) => {
  const defaultTextColor = 'titanium|base|75'
  const textColorClassName = flavorHue && styles.color
  const flavorHueCSS = flavorHue && {
    backgroundColor: flavorHue,
    color: 'transparent',
  }

  return (
    <Box
      className={classNames(styles.InfoBox, className)}
      padding='base'
      {...rest}
    >
      {children}
      {!loading && (
        <>
          <header className={styles.textGroup}>
            <Text
              className={classNames(
                styles.heading,
                textColorClassName,
                subHeading && description && styles.hideOverflow,
              )}
              color={defaultTextColor}
              message={heading}
              style={{ ...flavorHueCSS }}
              tagName='h5'
              typography='captionBaseBold'
            />
            <Text
              {...subHeading}
              className={classNames(styles.subHeading, textColorClassName)}
              color={defaultTextColor}
              style={{ ...flavorHueCSS }}
              tagName='p'
              typography='captionSmall'
            />
          </header>
          <Text
            {...description}
            className={classNames(
              styles.description,
              styles.textGroup,
              textColorClassName,
            )}
            color={defaultTextColor}
            style={{ ...flavorHueCSS }}
            tagName='p'
            typography='captionSmall'
          />
        </>
      )}
    </Box>
  )
}
