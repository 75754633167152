import React, { FC } from 'react'
import { Text, TextProps } from 'components/Text'

export interface HeadingProps
  extends Omit<TextProps, 'message' | 'typography' | 'values'> {
    content: string
  }

export const Heading: FC<HeadingProps> = ({ content, ...rest }) => (
  <Text
    message={{ content: content }}
    tagName='h3'
    typography='subtitleSmall'
    {...rest}
  />
)
