import React, { FC, useEffect, useState } from 'react'
import { parseISO, formatDistanceToNow, differenceInDays } from 'date-fns'
import { useInterval } from 'hooks/use-interval'
import styles from './Disconnected.module.scss'
import { Text } from 'components/Text'
import { DeviceButton } from '../DeviceButton'
import { disconnectedData } from './localization'
import { Icon } from '@juullabs/react-components'
import { DeviceCardChild } from '../../types'

export interface DisconnectedProps extends DeviceCardChild {
  /** timestamp of last device connection event */
  lastConnected: string
  /** action for the button on the disconnected card state */
  onClick(): void
}

type LastConnectedData = {
  /** alert message and icon,
   * associated with the device not having been paired in awhile */
  hasAlert: boolean
  /** span of time since the device has been connected */
  timeSinceConnected: string
}

const ALERT_ICON = 'icon/16x16/notification-alert'
const LAST_CONNECTED_INTERVAL_MS = 60000 // 1 minute
const ALERT_CUTOFF_DAYS = 10 // 10 days

export const Disconnected: FC<DisconnectedProps> = ({
  inactive = false,
  lastConnected,
  onClick,
}) => {
  const { alert, button, caption } = disconnectedData

  const formatLastConnected = (lastConnected: string):LastConnectedData => {
    const lastConnectedDate = parseISO(lastConnected)

    return ({
      hasAlert: differenceInDays(new Date(), lastConnectedDate) >= ALERT_CUTOFF_DAYS,
      timeSinceConnected: formatDistanceToNow(lastConnectedDate, { addSuffix: true }),
    })
  }

  const [{ hasAlert, timeSinceConnected }, setLastConnectedData]
    = useState<LastConnectedData>(formatLastConnected(lastConnected))

  useEffect(() => {
    if (!inactive) setLastConnectedData(formatLastConnected(lastConnected))
  }, [inactive])

  useInterval(() => {
    setLastConnectedData(formatLastConnected(lastConnected))
  }, !inactive ? LAST_CONNECTED_INTERVAL_MS : null)

  return (
    <>
      <div>
        <div className={styles.captionWithIcon}>
          {hasAlert && <Icon name={ALERT_ICON} />}
          <Text
            color='titanium|base|100'
            message={caption}
            tagName='p'
            typography='bodyBaseBold'
            values={{ timeSinceConnected }}
          />
        </div>
        {hasAlert && (
          <Text
            color='titanium|base|100'
            margin={{ top: 'extremeTight' }}
            message={alert}
            tagName='p'
            typography='bodySmall'
          />
        )}
      </div>
      <DeviceButton
        content={button}
        disabled={inactive}
        onClick={onClick}
      />
    </>
  )
}
